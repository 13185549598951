import {AUTH_AXIOS} from '@/modules/auth/api/backend-config';

export default {
    getUserInfo() {
        return AUTH_AXIOS.get('/user');
    },
    updateUserInfo(userData) {
        return AUTH_AXIOS.put('/user',
            JSON.stringify(userData),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            });
    },
    getAllRoles() {
        return AUTH_AXIOS.get('/roles');
    }
}
