export default [
    {
        name: "Сервисы",
        icon: "tools",
        children: []
    },
    {
        name: "Администрирование",
        icon: "gear-fill",
        children: []
    }
]