import auth from '@/modules/auth/api/service/auth-service';
import Cookies from 'js-cookie';
import {v1 as uuidv1} from 'uuid';

const DEVICE_ID_COOKIE = 'deviceId';
const COOKIE_PATH = '/api/auth';

export default {
    namespaced: true,
    state: {
        roles: [],
        isAuthorized: null,
        serviceRoles: []
    },
    actions: {
        refreshToken({dispatch, commit}) {
            return auth.refreshToken()
                .then(async () => {
                    commit("setAuthorized", true);
                    await dispatch("getRoles");
                })
                .catch(err => {
                    dispatch("unauthorize", err.response?.status === 401);
                    throw err;
                });
        },
        login({dispatch, commit}, phone) {
            dispatch("createDeviceIdCookie");
            return auth.login(phone)
                .then(response => {
                    commit("setServiceRoles", response.data);
                    return response;
                });
        },
        loginByPassword({dispatch, commit}, payload) {
            dispatch("createDeviceIdCookie");
            return auth.loginByPassword(payload.login, payload.password)
                .then(response => {
                    commit("setServiceRoles", response.data);
                    return response;
                });
        },
        auth({dispatch, commit}, payload) {
            return auth.authenticate(payload.phone, payload.code)
                .then(async response => {
                    return Promise.resolve(response);
                });
        },
        logout({dispatch}) {
            return auth.logout()
                .then(() => dispatch("unauthorize"))
                .catch(err => {
                    if (err.response?.status === 401) {
                        return dispatch("refreshToken")
                            .then(() => {
                                return auth.logout()
                                    .finally(() => dispatch("unauthorize"));
                            })
                            .catch(() => dispatch("unauthorize"));
                    } else {
                        console.error("Ошибка при выходе", err);
                        dispatch("unauthorize");
                    }
                });
        },
        changePassword({}, payload) {
            return auth.changePassword(payload.login, payload.password, payload.newPassword);
        },
        getRoles({dispatch, commit}) {
            return auth.getRoles()
                .then(response => {
                    commit("setRoles", response.data);
                })
                .catch(err => {
                    if (err.response?.status === 401) {
                        dispatch("unauthorize");
                    } else {
                        commit("setRoles", []);
                        console.error("Ошибка получения ролей", err);
                    }
                });
        },
        createDeviceIdCookie({}) {
            if (Cookies.get(DEVICE_ID_COOKIE) == null) {
                Cookies.set(DEVICE_ID_COOKIE, uuidv1(), {
                    path: COOKIE_PATH,
                    domain: process.env.VUE_APP_COOKIE_DOMAIN,
                    expires: parseInt(process.env.VUE_APP_DEVICE_ID_COOKIE_LIFE_TIME)
                });
            }
        },
        unauthorize({commit, dispatch}, removeCookie = true) {
            commit("setAuthorized", false);
            commit("setRoles", []);
            commit("setServiceRoles", [])
            if (removeCookie) {
                Cookies.remove(DEVICE_ID_COOKIE, {
                    path: COOKIE_PATH,
                    domain: process.env.VUE_APP_COOKIE_DOMAIN
                });
            }
        },
        updateServiceRoles({commit}) {
            auth.getServiceRoles()
                .then(response => commit("setServiceRolesUnmapped", response.data))
        }
    },
    mutations: {
        setAuthorized(state, isAuthorized) {
            state.isAuthorized = isAuthorized;
        },
        setRoles(state, roles) {
            state.roles = roles;
        },
        setServiceRoles(state, userInfo) {
            state.serviceRoles = userInfo.roleServices?.map(item => item.name);
        },
        setServiceRolesUnmapped(state, serviceRoles) {
            state.serviceRoles = serviceRoles
        }
    },
    getters: {
        isAuthorized(state) {
            return state.isAuthorized;
        },
        isAdmin(state) {
            return state.roles.includes('ADMIN');
        },
        isRolesEmpty(state) {
            return state.roles.length === 0;
        },
        getServiceRoles(state) {
            return state.serviceRoles;
        }
    }
};
