<template>
  <div class="page-margin">
    <b-container class="my-5">
      <div class="text-center">
        <h6 class="display-4">Профиль</h6>
      </div>
      <b-card class="mt-5" header-class="header-font" header-text-variant="primary" header="Личная информация">
        <b-form @submit.stop.prevent="saveProfile">
          <b-form-group label="Логин">
            <b-form-input v-model="userData.login" :readonly="true"/>
          </b-form-group>
          <b-form-group label="Номер телефона">
            <b-form-input v-model="userData.phone" :readonly="true"/>
          </b-form-group>
          <b-form-group label="Электронная почта">
            <b-form-input v-model="$v.userData.email.$model"
                          :state="$v.userData.email.$dirty ? !$v.userData.email.$error : null"
                          aria-describedby="email-validation"/>
            <b-form-invalid-feedback id="email-validation">
              Некорректный адрес электронной почты
            </b-form-invalid-feedback>
            <div v-if="!userData.emailConfirm && userData.email != null && userData.email.length > 0" class="warning">
              Необходимо подтвердить адрес электронной почты
            </div>
          </b-form-group>
          <b-form-group label="Фамилия">
            <b-form-input v-model="userData.lastName" required/>
          </b-form-group>
          <b-form-group label="Имя">
            <b-form-input v-model="userData.firstName" required/>
          </b-form-group>
          <b-form-group label="Отчество">
            <b-form-input v-model="userData.patronymic"/>
          </b-form-group>
          <b-form-group label="Дата рождения">
            <b-form-datepicker v-model="userData.dateOfBirth" placeholder="Выберите дату"/>
          </b-form-group>
          <b-button variant="primary" class="mt-2 mx-auto form-control" type="submit"
                    :disabled="submitting || $v.userData.email.$anyError">
            Сохранить
          </b-button>
        </b-form>
      </b-card>
      <b-card header-class="header-font" header-text-variant="primary" class="mt-5" header="Смена пароля">
        <password-change @changed="updatePassword"/>
        <b-alert class="mt-4" variant="danger" :show="incorrectOldPassword" dismissible
                 @dismissed="incorrectOldPassword=false">
          Неверно введён старый пароль
        </b-alert>
      </b-card>
      <b-card header-class="header-font" header-text-variant="primary" class="mt-5" header="Роли">
        <roles-table v-if="!rolesLoading" :services="userData.roleServices" :all-roles="allRoles"></roles-table>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import PasswordChange from "@/components/PasswordChange";
import RolesTable from "@/modules/profile/components/RolesTable";
import userService from "@/modules/profile/api/service/user-service";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "profile",
  components: {
    'password-change': PasswordChange,
    'roles-table': RolesTable
  },
  created() {
    document.title = this.$route.meta.title;
    this.fetchUserInfo()
        .finally(() => {
          if (this.isAuthorized) {
            this.fetchAllRoles();
          }
        });
  },
  data() {
    return {
      rolesLoading: true,
      incorrectOldPassword: false,
      submitting: false,
      allRoles: [],
      userData: {
        login: null,
        email: null,
        firstName: null,
        lastName: null,
        patronymic: null,
        dateOfBirth: null,
        phone: null,
        emailConfirm: null,
        services: [
          {
            name: null,
            description: null,
            roles: []
          }
        ]
      }
    }
  },
  validations() {
    return {
      userData: {
        email: {
          pattern: (value) => new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
        }
      }
    }
  },
  methods: {
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapActions("auth", ["refreshToken", "changePassword"]),
    ...mapMutations("auth", ["setServiceRoles"]),
    fetchUserInfo() {
      return userService.getUserInfo()
          .then(response => {
            this.userData = response.data;
            this.userData.emailConfirm = this.userData.email_confirm;
            delete this.userData.email_confirm;
            this.setServiceRoles(this.userData);
            return Promise.resolve();
          })
          .catch(async err => {
            if (err.response?.status !== 401) {
              this.$bvToast.toast('Не удалось загрузить личные данные', {
                title: 'Ошибка',
                autoHideDelay: 5000,
                appendToast: true
              });
            } else {
              await this.refreshToken()
                  .then(async () => {
                    await userService.getUserInfo()
                        .then(response => {
                          this.userData = response.data;
                          this.setServiceRoles(this.userData);
                          return Promise.resolve();
                        })
                        .catch(() => {
                          this.$bvToast.toast('Не удалось загрузить личные данные', {
                            title: 'Ошибка',
                            autoHideDelay: 5000,
                            appendToast: true
                          });
                          return Promise.reject();
                        });
                    return Promise.resolve();
                  })
                  .catch(() => {
                    this.$router.push("/login");
                  });
            }
            return Promise.reject();
          });
    },
    fetchAllRoles() {
      userService.getAllRoles()
          .then(response => {
            this.allRoles = response.data;
            return Promise.resolve();
          })
          .catch(async err => {
            if (err.response?.status !== 401) {
              this.$bvToast.toast('Не удалось получить список ролей', {
                title: 'Ошибка',
                autoHideDelay: 5000,
                appendToast: true
              });
            } else {
              await this.refreshToken()
                  .then(async () => {
                    await userService.getAllRoles()
                        .then(response => {
                          this.allRoles = response.data;
                          return Promise.resolve();
                        })
                        .catch(() => {
                          this.$bvToast.toast('Не удалось получить список ролей', {
                            title: 'Ошибка',
                            autoHideDelay: 5000,
                            appendToast: true
                          });
                          return Promise.reject();
                        });
                    return Promise.resolve();
                  })
                  .catch(() => {
                    this.$router.push("/login");
                  });
            }
            return Promise.reject();
          })
          .finally(() => {
            this.rolesLoading = false;
          });
    },
    saveProfile() {
      this.submitting = true;
      userService.updateUserInfo({
        email: this.userData.email,
        firstName: this.userData.firstName,
        lastName: this.userData.lastName,
        patronymic: this.userData.patronymic,
        dateOfBirth: this.userData.dateOfBirth
      }).then(() => {
        this.$bvToast.toast('Данные успешно обновлены', {
          variant: 'success',
          solid: true,
          noCloseButton: true
        });
      }).catch(err => {
        if (err.response?.status !== 401) {
          this.$bvToast.toast('Не удалось обновить личные данные', {
            title: 'Ошибка',
            autoHideDelay: 5000,
            appendToast: true
          });
        } else {
          this.refreshToken()
              .then(() => {
                userService.updateUserInfo({email: this.userData.email})
                    .then(() => {
                      this.$bvToast.toast('Данные успешно обновлены', {
                        variant: 'success',
                        solid: true,
                        noCloseButton: true
                      });
                    })
                    .catch(() => {
                      this.$bvToast.toast('Не удалось обновить личные данные', {
                        title: 'Ошибка',
                        autoHideDelay: 5000,
                        appendToast: true
                      });
                    })
              })
              .catch(() => {
                this.$router.push("/login");
              });
        }
      }).finally(() => {
        this.submitting = false;
      });
    },
    updatePassword(result) {
      this.changePassword({
        login: this.userData.login,
        password: result.oldPassword,
        newPassword: result.newPassword
      }).then(() => {
        this.incorrectOldPassword = false;
        this.$bvToast.toast('Пароль успешно сменён', {
          variant: 'success',
          solid: true,
          noCloseButton: true
        });
      }).catch(err => {
        if (err.response?.status === 403 || err.response?.status === 400) {
          this.incorrectOldPassword = true;
        } else {
          this.$bvToast.toast('Не удалось сменить пароль', {
            title: 'Ошибка',
            autoHideDelay: 5000,
            appendToast: true
          });
        }
      });
    }
  }
}
</script>
<style scoped>
.header-font {
  font-size: 22px;
}

.warning {
  color: orangered;
  font-size: 85%;
}
</style>