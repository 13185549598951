export default [
    {
        name: "Администрирование",
        children: [
            {
                name: "Профиль",
                icon: 'person',
                path: "/profile"
            }
        ]
    }
]