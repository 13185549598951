<template>
  <div class="environment">
    {{ getEnvironment == null ? '-' : getEnvironment }}
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "app-info",
  mounted() {
    this.$store.dispatch("appInfo/getEnvironment")
        .catch(() => {
          this.$bvToast.toast('Не удалось получить переменные среды', {
            title: 'Ошибка',
            autoHideDelay: 5000,
            appendToast: true
          });
        });
  },
  computed: {
    ...mapGetters("appInfo", ["getEnvironment"])
  }
}
</script>

<style scoped>
.environment {
  font-size: 16px;
}
</style>
