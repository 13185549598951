export default [
    {
        name: "Администрирование",
        children: [
            {
                name: "Пользователи",
                icon: "people",
                path: "/service/user-management"
            }
        ]
    },
    {
        name: "Сервисы",
        children: [
            {
                name: "Новости",
                icon: 'b-icon-newspaper',
                path: "/service/news",
                serviceCode: "NEWS"
            },
            {
                name: "Документы",
                icon: 'b-icon-file-earmark-text',
                path: "/service/docs",
                serviceCode: "DOCS"
            },
            {
                name: "Бонусы",
                icon: 'b-icon-coin',
                path: "/service/bonus",
                serviceCode: "BONUS"
            },
            {
                name: "Обновления",
                icon: 'b-icon-cloud-arrow-down',
                path: "/service/updates",
                serviceCode: "UPDATES"
            },
            {
                name: "Каталог",
                icon: 'b-icon-cart3',
                path: "/service/catalog",
                serviceCode: "CATALOG"
            },
            {
                name: "Инвентаризация",
                icon: 'b-icon-collection',
                path: "/service/inventory",
                serviceCode: "INVENTORY"
            },
            {
                name: "Тех. поддержка",
                icon: 'b-icon-bug',
                path: "/service/tech-support",
                serviceCode: "TECH"
            },
            {
                name: "Настройки",
                icon: 'b-icon-gear',
                path: "/service/settings",
                serviceCode: "SETTINGS"
            }
        ]
    }
]