import Profile from "@/modules/profile/views/Profile";
import Module from "@/modules/profile/Module";

export default {
    path: "/profile",
    component: Module,
    children: [
        {
            path: "",
            component: Profile,
            meta: {
                title: 'Профиль'
            }
        }
    ]
};