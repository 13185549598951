<template>
  <div>
    <b-form @submit.prevent="onSubmit" method="post">
      <b-input-group>
        <b-input-group-prepend is-text>
          <b-icon icon="person-fill"></b-icon>
        </b-input-group-prepend>
        <b-form-input v-model="username" placeholder="Логин" required="required"/>
      </b-input-group>
      <b-input-group class="mt-2">
        <b-input-group-prepend is-text>
          <b-icon icon="lock-fill"></b-icon>
        </b-input-group-prepend>
        <b-form-input v-model="password" placeholder="Пароль" type="password" required="required"/>
      </b-input-group>
      <b-button variant="primary" type="submit" class="mt-3 mx-auto form-control">Вход</b-button>
      <b-alert class="mt-2 small mb-0" variant="danger" :show="errorMessage != null" dismissible
               @dismissed="errorMessage=null">
        {{ errorMessage }}
      </b-alert>
    </b-form>
    <b-modal v-model="needChangePassword" hide-footer hide-header no-close-on-backdrop>
      <password-change :previous-password="password" @changed="updatePassword"/>
    </b-modal>
  </div>
</template>

<script>
import PasswordChange from "@/components/PasswordChange";
import {mapActions, mapMutations} from "vuex";

export default {
  name: "login-password-auth",
  components: {
    'password-change': PasswordChange
  },
  data() {
    return {
      username: '',
      password: '',
      errorMessage: null,
      needChangePassword: false
    }
  },
  methods: {
    ...mapActions("auth", ["loginByPassword", "changePassword", "unauthorize", "getRoles"]),
    ...mapMutations("auth", ["setAuthorized"]),
    onSubmit() {
      this.errorMessage = null;
      this.loginByPassword({
        login: this.username,
        password: this.password
      }).then(async response => {
        if (response.data?.changePassword === true) {
          this.needChangePassword = true;
        } else {
          const roleServices = response.data.roleServices;
          if (roleServices == null || roleServices.length === 0 ||
              (roleServices.length === 1 && (roleServices[0] == null || roleServices[0].name == null
                  || roleServices[0].name === "MOBILE"))) {
            this.errorMessage = "У вас нет доступа к этому сервису";
            this.unauthorize();
            return;
          }

          this.setAuthorized(true);
          await this.getRoles();
          this.$router.push('/profile');
        }
      }).catch(err => {
        if (err.response != null) {
          if (err.response.status === 403 || err.response.status === 400) {
            this.errorMessage = 'Введён неверный логин или пароль';
          } else {
            this.errorMessage = `Произошла ошибка при авторизации (код ${err.response.status})`;
          }
        } else {
          this.errorMessage = 'Произошла ошибка при авторизации';
        }
      });
      return false;
    },
    updatePassword(result) {
      this.changePassword({
        login: this.username,
        password: this.password,
        newPassword: result.newPassword
      }).then(() => {
        this.loginByPassword({
          login: this.username,
          password: result.newPassword
        }).then(() => {
          location.reload();
        }).catch(() => {
          this.$bvToast.toast('Не удалось авторизоваться после смены пароля', {
            title: 'Ошибка',
            autoHideDelay: 5000,
            appendToast: true
          });
        }).finally(() => {
          this.needChangePassword = false;
        });
      }).catch(() => {
        this.$bvToast.toast('Не удалось сменить пароль', {
          title: 'Ошибка',
          autoHideDelay: 5000,
          appendToast: true
        });
      });
    }
  }
}
</script>
