import Module from "@/modules/services/Module";
import ServicePage from "@/modules/services/views/ServicePage";

export default {
    path: "/service",
    component: Module,
    children: [
        {
            path: 'news',
            component: ServicePage,
            props: {
                url: process.env.VUE_APP_NEWS_UI_URL,
                microFrontend: true
            }
        },
        {
            path: 'docs',
            component: ServicePage,
            props: {
                url: process.env.VUE_APP_DOCS_UI_URL,
                microFrontend: true
            }
        },
        {
            path: 'updates',
            component: ServicePage,
            props: {
                url: process.env.VUE_APP_UPDATES_UI_URL,
                microFrontend: true
            }
        },
        {
            path: 'inventory',
            component: ServicePage,
            props: {
                url: process.env.VUE_APP_INVENTORY_UI_URL,
                microFrontend: true
            }
        },
        {
            path: 'tech-support',
            component: ServicePage,
            props: {
                url: process.env.VUE_APP_TECH_SUPPORT_UI_URL,
                microFrontend: true
            }
        },
        {
            path: 'user-management',
            component: ServicePage,
            props: {
                url: process.env.VUE_APP_USER_MANAGEMENT_UI_URL,
                microFrontend: true
            }
        },
        {
            path: 'catalog',
            component: ServicePage,
            props: {
                url: process.env.VUE_APP_CATALOG_UI_URL,
                microFrontend: true
            }
        },
        {
            path: 'bonus',
            component: ServicePage,
            props: {
                url: process.env.VUE_APP_BONUS_UI_URL,
                microFrontend: true
            }
        },
        {
            path: 'settings',
            component: ServicePage,
            props: {
                url: process.env.VUE_APP_SETTINGS_UI_URL,
                microFrontend: true
            }
        },
        {
            path: '*',
            component: ServicePage,
            props: {
                url: "",
                microFrontend: true
            }
        },
    ]
};