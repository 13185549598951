<template>
  <div v-if="!adminUIUsersOnly || !isRolesEmpty">
    <b-button squared @click="collapsable ? changeFolded() : menuClick()" :variant="!collapsable && isActive(path) ? 'primary' : 'light'"
              class="w-100 d-flex justify-content-between align-items-center" :class="!collapsable && isActive(path) ? '' : 'menu-item'">
      <div>
        <b-icon :icon="icon" class="mr-1"/>
        {{name}}
      </div>
      <b-icon scale="0.75" v-if="collapsable" :icon="unfolded ? 'chevron-down' : 'chevron-right'"/>
    </b-button>
    <div class="mb-1">
      <b-collapse v-if="collapsable" :visible="unfolded">
        <b-button style="font-size: 15px" squared
                  v-for="child in children" :key="child.name"
                  v-if="(!child.adminUIUsersOnly || !isRolesEmpty) && childAccessAllowed(child)"
                  :variant="isActive(child.path) ? 'primary' : 'light'"
                  class="pl-4 pt-1 pb-1 pr-1 w-100 d-flex"
                  @click="menuClick(child)">
          <b-icon :icon="child.icon" class="mr-1 pt-1" scale="1.15"></b-icon>
          {{ child.name }}
        </b-button>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "menu-item",
  props: {
    path: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    children: {
      type: Array,
      required: false,
      default: null
    },
    adminUIUsersOnly: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  data() {
    return {
      unfolded: false
    }
  },
  mounted() {
    if (this.children != null && this.children.length > 0) {
      this.unfoldSelectedMenuItem();
    }
  },
  computed: {
    collapsable() {
      return this.children != null && this.children.length > 0;
    },
    ...mapGetters("auth", ["isRolesEmpty", "getServiceRoles"])
  },
  methods: {
    menuClick(child = null) {
      const pathToGo = child == null ? this.path : child.path;
      if (this.$router.currentRoute.path !== pathToGo) {
        this.$router.push(pathToGo);
      }
    },
    changeFolded() {
      this.unfolded = !this.unfolded;
    },
    isActive(path) {
      return this.$route.path.startsWith(path);
    },
    unfoldSelectedMenuItem() {
      if (this.$route.path === '/') {
        setTimeout(this.unfoldSelectedMenuItem, 250);
      } else {
        this.unfolded = this.children.some(child => this.$route.path.startsWith(child.path));
      }
    },
    childAccessAllowed(child) {
      return (!child.serviceCode || this.getServiceRoles.find(role => ['ROOT', child.serviceCode].includes(role)));
    }
  }
}
</script>

<style scoped>
.btn:focus {
  outline: none;
  box-shadow: none;
}

.menu-item {
  color: #4285F4;
}
</style>