<template>
  <div>
    <b-sidebar v-if="isAuthorized" width="15%" id="sidebar-no-header" aria-labelledby="sidebar-no-header-title"
               shadow visible noCloseOnRouteChange>
      <template #header="{ hide }">
        <b-container class="pl-2 py-1 border-bottom w-100">
          <b-row>
            <b-col>
              <h4 style="color: #4285F4; font-weight: bold; font-size: 28px">Меню</h4>
            </b-col>
            <b-col class="pr-0" style="margin: auto">
              <app-info/>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <div class="mt-3">
        <menu-item v-for="item in getMenu" :key="item.name"
                   class="border-bottom mb-1"
                   :icon="item.icon" :name="item.name"
                   :children="item.children" :path="item.path"
                   :admin-u-i-users-only="item.adminUIUsersOnly"/>
      </div>
      <template #footer="{ hide }">
        <div class="px-3 py-3">
          <b-button variant="danger" block @click="logoutClicked">Выйти</b-button>
        </div>
      </template>
    </b-sidebar>
    <router-view />
    <div id="subapp">
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import MenuItem from "@/components/MenuItem";
import AppInfo from "@/modules/app-info/components/AppInfo.vue";

export default {
  name: "app",
  components: {
    "menu-item": MenuItem,
    "app-info": AppInfo
  },
  mounted() {
    document.title = "Личный кабинет";
    window.addEventListener("message", (event) => {
      if (event.data === "LOGOUT") {
        this.unauthorize();
        this.$router.push("/login");
      }
    }, false);
  },
  computed: {
    ...mapGetters("auth", ["isAuthorized", "getServiceRoles"]),
    ...mapGetters("menu", ["getMenu"])
  },
  methods: {
    ...mapMutations("auth", ["unauthorize"]),
    ...mapActions("auth", ["logout", "updateServiceRoles"]),
    logoutClicked() {
      this.logout()
          .finally(() => {
            this.$router.push("/login");
          });
    }
  },
  watch: {
    isAuthorized: function (newValue) {
      if (newValue && !this.getServiceRoles.length) {
        this.updateServiceRoles();
      }
    }
  }
};
</script>
<style>
.page-margin {
  margin-left: 15%;
}

#subapp {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 85%;
  border: none;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  z-index: -1;
}
</style>