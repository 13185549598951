<template>
  <div>
    <iframe v-if="!microFrontend" class="iframe" :src='url'>
    </iframe>
  </div>
</template>
<script>
export default {
  name: 'service-page',
  props: {
    url: {
      type: String,
      required: true
    },
    microFrontend: {
      type: Boolean,
      required: true
    },
  },
  created() {
    if (this.$route.meta) {
      document.title = this.$route.meta.title;
    }
  },
  updated() {
    if (this.$route.meta) {
      document.title = this.$route.meta.title;
    }
  }
};
</script>
<style scoped>
#subapp, .iframe {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 85%;
  border: none;
  margin: 0;
  padding: 0;
  z-index: 99999;
  height: 100%;
}

#subapp {
  overflow: auto;
  z-index: 99999;
}

.iframe {
  overflow: auto;
  z-index: 999999;
}
</style>