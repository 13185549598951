import router from "./router";
import store from "./store";

const registerModule = (name, module) => {
    if (module.store) {
        store.registerModule(name, module.store);
    }

    if (module.router) {
        router.addRoute(module.router);
    }

    if (module.menu) {
        store.commit("menu/addMenuItems", module.menu)
    }
};

export const registerModules = modules => {
    Object.keys(modules).forEach(moduleKey => {
        const module = modules[moduleKey];
        registerModule(moduleKey, module);
    });
};