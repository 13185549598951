export default {
    namespaced: true,
    state: {
        menu: [],
    },
    mutations: {
        setMenu(state, menu) {
            state.menu = menu;
        },
        addMenuItems(state, menuItems) {
            menuItems.forEach(item => state.menu.find(menuItem => menuItem.name === item.name)
                .children.push(...item.children));
        }
    },
    getters: {
        getMenu(state) {
            return state.menu;
        }
    }
}