<template>
  <b-form @submit.prevent>
    <b-form-group v-if="isAuthorized" label="Старый пароль">
      <b-form-input v-model="oldPassword" required="required" type="password"/>
    </b-form-group>
    <b-form-group>
      <b-form-group label="Новый пароль">
        <b-form-input v-model="$v.newPassword.$model" required="required"
                      type="password"
                      :state="$v.newPassword.$dirty ? !$v.newPassword.$error : null">
        </b-form-input>
      </b-form-group>
      <b-form-group class="mb-2" label="Повторите пароль">
        <b-form-input v-model="$v.verifyNewPassword.$model" required="required"
                      type="password"
                      :state="$v.verifyNewPassword.$dirty ? !$v.verifyNewPassword.$error : null">
        </b-form-input>
      </b-form-group>
      <div class="validation" v-if="!$v.newPassword.pattern">
        Пароль должен быть не менее 8 символов длиной и содержать хотя бы одну маленькую, большую буквы и цифру
      </div>
      <div class="validation" v-if="!$v.newPassword.doNotMatchOldPassword">Новый и старый пароли не должны совпадать
      </div>
      <div class="validation" v-if="!$v.verifyNewPassword.sameAsPassword">Пароли не совпадают</div>
      <b-button variant="primary" @click="changePassword" class="mt-2 mx-auto form-control">
        Сменить пароль
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    previousPassword: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      oldPassword: this.previousPassword,
      newPassword: null,
      verifyNewPassword: null
    }
  },
  validations() {
    return {
      newPassword: {
        pattern: (value) => new RegExp("^(?=\\w*\\d)(?=\\w*[A-Z])(?=\\w*[a-z])\\w{8,}$").test(value),
        doNotMatchOldPassword: (value) => this.isAuthorized || this.newPassword !== this.verifyNewPassword || value !== this.oldPassword
      },
      verifyNewPassword: {
        sameAsPassword: (value) => value === this.newPassword
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["isAuthorized"])
  },
  methods: {
    changePassword() {
      this.$v.newPassword.$touch();
      this.$v.verifyNewPassword.$touch();
      if (this.$v.newPassword.$anyError || this.$v.verifyNewPassword.$anyError) {
        return;
      }
      this.$emit('changed', {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword
      });
      this.oldPassword = null;
      this.newPassword = null;
      this.verifyNewPassword = null;
      this.$v.$reset();
    }
  },
}
</script>
<style scoped>
.validation {
  color: #D8000C;
  font-size: 85%;
  margin-bottom: 1%;
}
</style>