import appInfoService from "@/modules/app-info/api/service/app-info-service";

export default {
    namespaced: true,
    state: {
        environment: null
    },
    actions: {
        getEnvironment({commit}) {
            appInfoService.getEnvironment()
                .then(response => {
                    commit("setEnvironment", response.data?.environment);
                })
                .catch(error => {
                    console.error("Ошибка при получении текущего окружения", error);
                });
        }
    },
    mutations: {
        setEnvironment(state, environment) {
            state.environment = environment;
        }
    },
    getters: {
        getEnvironment(state) {
            return state.environment;
        }
    }
}